import { mfrNames } from '@/assets/lists'

const mfrMap = new Map(mfrNames.map(mfr => [mfr.value, mfr.seriesColor]))

function getMfrColors(labels) {
    const colors = []
    for (const label of labels) {
        const color = mfrMap.get(label)
        if (color) {
            colors.push(color)
        } else {
            colors.push(getRandomColor())
        }
    }

    return colors
}

// function getMfrColors(labels) {
//     const colors = []
//     for (const label of labels) {
//         const mfr = mfrNames.find(m => m.value === label)
//         if (mfr && mfr.seriesColor) {
//             colors.push(mfr.seriesColor)
//         } else {
//             colors.push(getRandomColor())
//         }
//     }

//     return colors
// }

function getRandomColor() {
    const randomHex = Math.floor(Math.random() * 16777215).toString(16)

    return `#${'0'.repeat(6 - randomHex.length)}${randomHex}`
}

export default getMfrColors
