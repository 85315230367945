<template>
  <v-fade-transition mode="out-in">
    <v-icon
      :key="isDark"
      @click="isDark = !isDark"
    >
      {{ isDark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
    </v-icon>
  </v-fade-transition>
</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'
import useAppConfig from '@core/@app-config/useAppConfig'
import { usePreferredColorScheme } from '@vueuse/core'

export default {
    name: 'AppBarThemeSwitcher',
    setup() {
        const { isDark } = useAppConfig()
        const preferredColor = usePreferredColorScheme()

        return {
            isDark,
            preferredColor,

            // Icons
            icons: {
                mdiWeatherNight,
                mdiWeatherSunny,
            },
        }
    },
    watch: {
        isDark: {
            handler() {
                if (this.isDark) {
                    document.documentElement.classList.add('tw-dark')
                } else {
                    document.documentElement.classList.remove('tw-dark')
                }
                this.$store.commit('appConfig/UPDATE_APP_SKIN_VARIANT', this.$store.state.appConfig.app.skinVariant)
            },
            immediate: true,
            deep: true,
        },
    },
    mounted() {
    },
    methods: {
        darkmodestate() {
            this.isDark = !this.isDark
            const sv = this.isDark === true ? 'bordered' : 'default'
            this.$store.commit('appConfig/UPDATE_APP_SKIN_VARIANT', sv)

            return this.isDark ? document.documentElement.classList.add('tw-dark') : document.documentElement.classList.remove('tw-dark')
        },
    },
}
</script>

<style>
</style>
