/**
 * It takes a number and rounds it to the nearest integer.
 * @param value - The value to round.
 * @param precision - The number of decimal places to round to.
 * @returns The function is being returned.
 */
function roundInt(value, precision) {
    if (precision === undefined) {
        precision = 0
    }
    if (value === undefined || value === null || value === 0) {
        return 0
    }

    const scale = Math.pow(10, precision)

    return Math.round(value * scale) / scale
}

export default roundInt
