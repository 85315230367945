import { getMfrColors } from '@/util/functions'

function updateSeriesWithColors(series) {
    const labels = series.map(s => s.name)
    const colors = getMfrColors(labels)

    for (let i = 0; i < series.length; i++) {
        series[i].fillColor = colors[i]
        series[i].strokeColor = colors[i]
        series[i].color = colors[i]
    }

    return series
}

export default updateSeriesWithColors
