/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia'
import { apiService } from '@/api'
import { useFilterStore } from '@/stores/FilterStore'
import { roundInt, currencyConvert } from '@/util/functions'

// import { arrayToObject } from '@/util/functions'

let dataObject = {}

function mergeData(pharmacyRatesData, groupRebateValues, pharmacyMoleculeList) {
    if (!Object.keys(groupRebateValues).length) {
        console.error('groupRebateValues is an empty object')

        return []
    }

    const tableData = pharmacyRatesData.map(rate => {
        const foundMolecule = pharmacyMoleculeList.find(
            molecule => molecule.genericProductNumber === rate.genericProductNumber,
        )

        return {
            ...rate,
            ...foundMolecule,
            rebate: groupRebateValues[rate.genericProductNumber].toString(),
        }
    })

    return tableData
}

function sumOfSeries(chartData) {
    try {
        const { labels, series } = chartData.MfrMarketSharePieChartWatchList.value
        const tarIndex = labels.indexOf('TAR')
        const ranIndex = labels.indexOf('RAN')
        let taroSales = 0

        if (tarIndex !== -1) {
            taroSales += series[tarIndex]
        }

        if (ranIndex !== -1) {
            taroSales += series[ranIndex]
        }

        const total = series.reduce((sum, value) => sum + value, 0)
        const taroMarketShare = (taroSales / total) * 100

        return {
            total,
            taroSales,
            taroMarketShare: `${taroMarketShare.toFixed(2)}%`,
        }
    } catch (error) {
        return 'Error: Required data not found'
    }
}

export const useDataStore = defineStore('DataStore', {
    state: () => ({
        pharmacyMoleculeList: [],
        moleculeSnapshot: [],
        chartData: [],
        pharmacyRatesData: [],
        groupRebateValues: {},
    }),

    getters: {
        stats: state => {
            const filterStore = useFilterStore()

            function totalSum(arr) {
                const result = arr.reduce(
                    (acc, curr) => {
                        acc.sumOfSales += curr.salesSum || 0
                        acc.sumOfTaroSales += curr.taroSalesSum || 0

                        return acc
                    },
                    { sumOfSales: 0, sumOfTaroSales: 0 },
                )

                result.sumOfSales = Number(result.sumOfSales.toFixed(2))
                result.sumOfTaroSales = Number(result.sumOfTaroSales.toFixed(2))

                result.marketShare = (result.sumOfTaroSales / result.sumOfSales) * 100
                result.marketShare = `${Number(result.marketShare.toFixed(2))}%`

                return result
            }
            if (state.pharmacyMoleculeList.length > 0) {
                if (filterStore.rxListFilter.value === 'targetView') {
                    // filter out all objects that contain target === false and return the new array
                    const filteredArray = state.pharmacyMoleculeList.filter(
                        item => item.target === true
              && item.salesSum > filterStore.threshold
              && item.marketShare < filterStore.marketShare,
                    )

                    const totalSalesNum = totalSum(filteredArray)

                    return {
                        totalSalesNum,
                    }
                }
                const totalSalesNum = totalSum(state.pharmacyMoleculeList)

                return {
                    totalSalesNum,
                }
            }
        },

        getDataObject: state => {
            if (state.moleculeSnapshot.length > 0) {
                dataObject = {
                    ...dataObject,

                    totalSalesNum: state.moleculeSnapshot[0].value[0].totalSalesNum || 0,
                    totalTaroSales:
            state.moleculeSnapshot[1].value && state.moleculeSnapshot[1].value.length > 0
                ? state.moleculeSnapshot[1].value[0].totalTaroSales
                : 0,
                }

                return dataObject
            }

            return dataObject
        },
        getDrugRank: state => {
            const FilterStore = useFilterStore()
            if (FilterStore.selectedDrugs === 0) {
                return 0
            }
            if (FilterStore.selectedDrugs.length > 0 && FilterStore.selectedDrugs[0].genericProductNumber) {
                if (state.pharmacyMoleculeList.length > 0) {
                    state.pharmacyMoleculeList.sort((a, b) => b.salesSum - a.salesSum)

                    const index = state.pharmacyMoleculeList.findIndex(
                        item => item.genericProductNumber === FilterStore.selectedDrugs[0].genericProductNumber,
                    )

                    // return the rank of the selected drug
                    return index + 1
                }
            }
        },
        getTaroShare: state => {
            const FilterStore = useFilterStore()
            if (FilterStore.selectedDrugs === 0) {
                return 0
            }
            if (FilterStore.selectedDrugs.length > 0 && FilterStore.selectedDrugs[0].genericProductNumber) {
                const index = state.pharmacyMoleculeList.findIndex(
                    item => item.genericProductNumber === FilterStore.selectedDrugs[0].genericProductNumber,
                )

                if (state.pharmacyMoleculeList[index].taroSalesSum > 0) {
                    return (
                        (state.pharmacyMoleculeList[index].taroSalesSum / state.pharmacyMoleculeList[index].salesSum)
            * 100
                    ).toFixed(2)
                }

                return 0
            }

            // return 0
        },

        tableData: state => {
            if (state.pharmacyRatesData.length > 0 && state.groupRebateValues && state.pharmacyMoleculeList.length > 0) {
                return mergeData(state.pharmacyRatesData, state.groupRebateValues, state.pharmacyMoleculeList)
            }

            return []
        },
        quickStats: state => {
            const totalGenericPotential = sumOfSeries(state.chartData)

            return { totalGenericPotential }
        },
    },

    actions: {
        async setMoleculeSnapshot(body) {
            this.moleculeSnapshot = await Promise.allSettled([
                apiService.getData('totalSalesNum', body),
                apiService.getData('totalTaroSales', body),
            ])

            return this.moleculeSnapshot
        },
        resetMoleculeSnapshot() {
            this.moleculeSnapshot = []

            return this.moleculeSnapshot
        },

        /*
        setDataObject(key: string | string[], body = {}) => Promise<{ [key: string]: any }>
        This function sets the chartData object with data fetched from the API using the apiService.getData method.

        Parameters:
        key: a string or an array of strings representing the keys to be used as the property names for the chart data objects.
        body: an optional object that can be used to send additional data with the API request.
        Returns:
        A Promise that resolves to an object with the chart data organized by the provided keys. The property names of the object are the values of key and the property values are the resolved promises from the API requests.
        */
        async setDataObject(key, body = {}) {
            if (typeof key === 'string') {
                key = [key]
            }

            const promises = key.map(async item => apiService.getData(item, body))
            const results = await Promise.allSettled(promises)

            this.chartData = results.reduce((acc, { status, value }, index) => {
                acc[key[index]] = { status, value }

                return acc
            }, {})

            return this.chartData
        },

        async setPharmacyMoleculeList() {
            const [moleculeData, taroData] = await Promise.all([
                apiService.getData('PharmacyMoleculeListTable', {}),
                apiService.getData('PharmacyMoleculeListTable', { DIN: true }),
            ])

            this.pharmacyMoleculeList = moleculeData.map(molecule => {
                const taroSale = taroData.find(taro => taro.genericProductNumber === molecule.genericProductNumber)

                molecule.taroSalesSum = taroSale ? taroSale.salesSum : 0
                molecule.marketShare = taroSale ? Number(roundInt((taroSale.salesSum / molecule.salesSum) * 100, 2)) : 0

                return molecule
            })
        },
        async setRatesDrugList() {
            const [ratesDrugList] = await Promise.allSettled([apiService.getData('taroMoleculeListAgg', {})])
            this.pharmacyRatesData = ratesDrugList.value

            return this.pharmacyRatesData
        },
        async setPharmacyRatesData(body) {
            const filterStore = useFilterStore()
            const ratesSavedData = await apiService.setPharmacyRatesData({
                ...body,
                _id: filterStore.selectedPharmacy[0].Accreditation_Number,
            })

            return ratesSavedData
        },
        async getPharmacyRatesData() {
            const filterStore = useFilterStore()
            const groupRebateValues = await apiService.getPharmacyRatesData({
                _id: filterStore.selectedPharmacy[0].Accreditation_Number,
            })
            if (!Object.keys(groupRebateValues).length) {
                this.groupRebateValues = this.pharmacyRatesData.reduce((acc, item) => {
                    const { genericProductNumber, rebate } = item
                    acc[genericProductNumber] = rebate || 0

                    return acc
                }, {})

                return this.groupRebateValues
            }

            if (groupRebateValues.length === 0) {
                this.groupRebateValues = this.pharmacyRatesData.reduce((acc, item) => {
                    const { genericProductNumber, rebate } = item
                    acc[genericProductNumber] = rebate || 0

                    return acc
                }, {})
            } else {
                this.groupRebateValues = groupRebateValues[0]
            }

            return this.groupRebateValues
        },

        resetState() {
            this.pharmacyMoleculeList = []
            this.moleculeSnapshot = []
            this.chartData = []
            this.pharmacyRatesData = []
            this.groupRebateValues = {}
        },
    },
})
