import {
    mdiAccountClockOutline, mdiAvTimer, mdiCheckboxMarkedCircle, mdiGestureDoubleTap, mdiFax, mdiTruckDelivery
} from '@mdi/js'

const statusList = {
    complete: {
        value: 'complete',
        text: 'Complete',
        color: 'primary',
        icon: mdiCheckboxMarkedCircle
    },
    inprogress: {
        value: 'inprogress',
        text: 'In Progress',
        color: 'success',
        icon: mdiAvTimer
    },
    pending: {
        value: 'pending',
        text: 'Pending',
        color: 'warning',
        icon: mdiAccountClockOutline
    },
    action: {
        value: 'action',
        text: 'Action',
        color: 'error',
        icon: mdiGestureDoubleTap
    },
    transfer: {
        value: 'transfer',
        text: 'Transfer',
        color: 'error',
        icon: mdiFax
    },
    ship: {
        value: 'ship',
        text: 'Shipped',
        color: 'success',
        icon: mdiTruckDelivery
    },
}

export default statusList
