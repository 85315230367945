<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col><quick-select @refreshAllData="refreshAllData"></quick-select></v-col>
          <v-col>
            <VueCtkDateTimePicker
              id="date-time-picker"
              v-model="dateRange"
              inline
              left
              label="Select Date Range"
              :max-date="currentDate"
              formatted="ll"
              range
              format="YYYY-MM-DD"
              :dark="isDark"
              class="tw-z-100"
              no-shortcuts
              no-clear-button
              no-keyboard
              @input="sortDateRange"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import useAppConfig from '@core/@app-config/useAppConfig'
import { useFilterStore } from '@/stores/FilterStore'
import quickSelect from '@/components/datePickers/quickSelect.vue'

export default {
    name: 'DateRangePickerDialog',
    components: {
        VueCtkDateTimePicker,
        quickSelect,
    },
    props: {
        dateRangeProp: {
            type: Array,
            default: () => [],
        },
    },
    setup() {
        const filterStore = useFilterStore()
        const { isDark } = useAppConfig()

        // const customShortcuts = [
        //     { key: 'thisWeek', label: 'This week', value: 'isoWeek' },
        //     { key: 'lastWeek', label: 'Last week', value: '-isoWeek' },
        //     { key: 'last7Days', label: 'Last 7 days', value: 7 },
        //     { key: 'last30Days', label: 'Last 30 days', value: 30 },
        //     { key: 'last60Days', label: 'Last 60 days', value: 60 },
        //     { key: 'thisMonth', label: 'This month', value: 'month' },
        //     { key: 'lastMonth', label: 'Last month', value: '-month' },
        //     { key: 'thisYear', label: 'This year', value: 'year' },
        //     { key: 'lastYear', label: 'Last year', value: '-year' },
        //     { key: 'last2Years', label: 'Last 2 years', value: '-year' }
        // ]

        return {
            filterStore,

            // customShortcuts,
            isDark
        }
    },
    data() {
        return {
            // dateRange: {
            //     end: moment().format('YYYY-MM-DD'),
            //     start: moment().subtract(6, 'month').format('YYYY-MM-DD'),
            // },
            dateRange: {
                end: this.filterStore.dateRange[1] || moment().format('YYYY-MM-DD'),
                start: this.filterStore.dateRange[0] || moment().subtract(3, 'month').format('YYYY-MM-DD')
            },
            sortedDateFilter: [],
            currentDate: moment().format('YYYY-MM-DD'),
        }
    },

    watch: {
        dateRangeProp: {
            handler() {
                if (this.dateRangeProp.length > 0) {
                    this.dateRange = {
                        end: this.dateRangeProp[1],
                        start: this.dateRangeProp[0],
                    }
                }

                // this.dateRange = this.dateRangeProp
            },

            immediate: true,
            deep: true,
        },
    },
    methods: {
        sortDateRange() {
            this.sortedDateFilter = [this.dateRange.start, this.dateRange.end]
            this.filterStore.setDateRange(this.sortedDateFilter)

            // await this.$emit('input', this.sortedDateFilter)
        },
        refreshAllData() {
            this.$emit('refreshAllData')
        },
    },
}
</script>
