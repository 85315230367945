import { getInstance } from '@/auth/authWrapper'
import { useUserStore } from '@/stores/User'
import * as Sentry from '@sentry/vue'

function sentryUser() {
    const authService = getInstance()
    const userStore = useUserStore()
    const id = authService.user.sub.replace('auth0|', '')

    Sentry.setUser({
        email: userStore.getToken.email,
        id,
        username: userStore.getToken.nickname,
    })
}

// eslint-disable-next-line import/prefer-default-export
export { sentryUser }
