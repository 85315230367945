/* eslint-disable import/no-cycle */
/* eslint-disable import/order */
// import market from './routes/market'

import { authGuard } from '@/auth'
import Vue from 'vue'
import VueRouter from 'vue-router'
import pharmacy from './routes/pharmacy'
import dashboard from './routes/dashboard'
import sos from './routes/sos'
import iqReport from './routes/iqReport'
import { useBreadcrumbs } from '@/stores/Breadcrumbs'
import { createPinia, PiniaVuePlugin } from 'pinia'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
Vue.use(pinia)
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: 'home',
    },
    {
        path: '/home',
        name: 'home',
        beforeEnter: authGuard,
        component: () => import('@/views/Home.vue'),
        meta: {
            layout: 'content',
            breadcrumb: 'Home',
        },

    // children: [...market, ...pharmacy, ...sos, ...dashboard],
    // children: [...pharmacy, ...sos, ...dashboard],
    },
    ...pharmacy,
    ...sos,
    ...dashboard,
    ...iqReport,
    {
        path: '/market/watchlist',
        name: 'market-watch-list',
        beforeEnter: authGuard,
        component: () => import('@/components/charts/watchList.vue'),
        meta: {
            layout: 'content',
            breadcrumb: 'Market Watch List',
        },
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/Error404.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
        },
    },
    {
        path: '*',
        redirect: 'error-404',
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
})

router.beforeEach((to, from, next) => {
    if (to.meta.breadcrumb === 'Home') {
        const breadcrumbs = useBreadcrumbs()
    }
    if (to.meta.breadcrumb) {
        const breadcrumbs = useBreadcrumbs()
        breadcrumbs.addBreadcrumb({
            text: to.meta.breadcrumb,
            to: to.path,
        })
    }

    next()
})

router.afterEach((to, from) => {
    if (from.meta.breadcrumb) {
        const breadcrumbs = useBreadcrumbs()
        breadcrumbs.removeBreadcrumb(from.meta.breadcrumb)
    }
})

export default router
