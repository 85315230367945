<template>
  <div>
    <v-row
      :key="btnKey"
      align="center"
    >
      <v-col
        v-for="(filter, index) in filters"
        :key="index"
        cols="2"
        sm="2"
        md="2"
      >
        <v-tooltip
          bottom
          color="warning"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              :text="!!filter.txtBtn"
              block
              icon
              v-bind="attrs"
              :disabled="filter.needsPharmacy && !filterStore.selectedPharmacy[0]"
              @click="handleClick(filter, index)"
              v-on="on"
            >
              <span
                v-if="filter.txtBtn"
                class="tw-text-red-500 tw-tex-hard"
              >{{ filter.txtBtn }}</span>
              <v-icon
                v-else
                :color="filter.iconColor"
              >
                {{ filter.icon }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ filter.tooltip }}</span>
        </v-tooltip>
        <v-dialog
          v-model="filter.dialog"
          :max-width="filter.maxWidth"
          :fullscreen="filter.fullscreen"
          persistent
        >
          <v-card>
            <v-card-title class="white--text primary darken-4">
              <span class="tw-text-white tw-font-bold">{{ filter.title }}</span>

              <v-spacer></v-spacer>

              <v-btn
                color="white"
                class="text--primary"
                fab
                small
                @click="filter.dialog = false"
              >
                <v-icon>{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </v-card-title>
            <!-- <v-card-title>{{ filter.title }}</v-card-title> -->
            <v-card-text>
              <component
                :is="filter.component"
                :date-range-prop="filterStore.dateRange"
                :selected-pharmacy-prop="filterStore.selectedPharmacy[0]"
                :selected-molecules-prop="filterStore.selectedDrugs"
                @refreshAllData="refreshAllData"
                @resetPharmacy="resetPharmacy"
              />
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col cols="6">
                  <v-btn
                    v-if="filters[1].dialog"
                    block
                    color="primary"
                    @click="resetPharmacy(index)"
                  >
                    <span>Reset</span>
                    <v-icon
                      right
                      class="me-2"
                    >
                      {{ icons.mdiPlaylistCheck }}
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="filters[2].dialog"
                    block
                    color="primary"
                    @click="resetMolecule(index)"
                  >
                    <span>Reset</span>
                    <v-icon
                      right
                      class="me-2"
                    >
                      {{ icons.mdiPlaylistCheck }}
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    block
                    color="primary"
                    @click="refreshAllData(filter, index)"
                  >
                    <span>Apply</span>
                    <v-icon
                      right
                      class="me-2"
                    >
                      {{ icons.mdiPlaylistCheck }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref } from 'vue'
import {
    mdiClose, mdiTag, mdiPlaylistCheck, mdiMagnify, mdiAlertCircleOutline, mdiCalendar, mdiHospital, mdiTestTube, mdiFilterRemove
} from '@mdi/js'
import datePicker from '@/components/filters/datePicker.vue'
import { useFilterStore } from '@/stores/FilterStore'
import { usePharmacyListStore } from '@/stores/PharmacyListStore'
import { useDataStore } from '@/stores/Data'
import pharmacySelect from '@/components/filters/pharmacySelect.vue'
import moleculeSelect from '@/components/filters/moleculeSelect.vue'
import newRatesTable from '@/views/sos/newRatesTable.vue'

// import router from '@/router'

export default {
    name: 'AppBarFilter',
    components: {
        datePicker,
        pharmacySelect,
        newRatesTable,
        moleculeSelect,
    },
    setup() {
        const btnKey = ref(0)
        const filterStore = useFilterStore()
        const pharmacyListStore = usePharmacyListStore()
        const dataStore = useDataStore()

        const filters = ref([
            {
                title: 'Date',
                icon: mdiCalendar,
                iconColor: 'primary',
                dialog: false,
                component: 'datePicker',
                route: null,
                maxWidth: '600px',
                needsPharmacy: false,
                fullscreen: false,
                tooltip: 'Date Range Filter',
            },
            {
                title: 'Pharmacy',
                icon: mdiHospital,
                iconColor: 'primary',
                dialog: false,
                component: 'pharmacySelect',
                route: null,
                maxWidth: '600px',
                needsPharmacy: false,
                fullscreen: false,
                tooltip: 'Pharmacy Filter',
            },
            {
                title: 'Molecule',
                icon: mdiTestTube,
                iconColor: 'primary',
                dialog: false,
                component: 'moleculeSelect',
                route: null,
                maxWidth: '600px',
                needsPharmacy: false,
                fullscreen: false,
                tooltip: 'Molecule Filter',
            },
            {
                title: 'Rates',
                icon: mdiTag,
                iconColor: 'primary',
                dialog: false,
                component: null,
                route: 'sos-rates-table',
                maxWidth: '100%',
                needsPharmacy: true,
                fullscreen: true,
                tooltip: 'Click here to view and customize rates for the selected pharmacy',
            },
            {
                title: 'Reset',
                icon: mdiFilterRemove,
                txtBtn: 'Reset',
                iconColor: 'error',
                dialog: false,
                component: '',
                maxWidth: '100%',
                needsPharmacy: false,
                fullscreen: false,
                tooltip: 'Reset Filters',
            },
        ])

        return {
            btnKey,
            filterStore,
            pharmacyListStore,
            dataStore,
            filters,
            icons: {
                mdiMagnify,
                mdiAlertCircleOutline,
                mdiPlaylistCheck,
                mdiTag,
                mdiFilterRemove,
                mdiClose
            },
        }
    },
    watch: {
        filterStore: {
            handler() {
                this.filters[0].iconColor = (this.filterStore.getFilterQuery?.RxDate && this.filterStore.getFilterQuery?.RxDate.length > 0) ? 'success' : 'primary'
                this.filters[1].iconColor = (this.filterStore.getFilterQuery?.Accreditation_Number && this.filterStore.getFilterQuery?.Accreditation_Number.length > 0) ? 'success' : 'primary'
                this.filters[2].iconColor = (this.filterStore.getFilterQuery?.genericProductNumber && this.filterStore.getFilterQuery?.genericProductNumber.length > 0) ? 'success' : 'primary'
            },
            deep: true,
            immediate: true,
        },
        $route: {
            handler() {
                this.refreshAllData()
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {
        this.pharmacyListStore.setMoleculeList()
    },
    methods: {
        resetPharmacy(index) {
            this.filterStore.setSelectedPharmacy([])
            this.refreshAllData()
            this.filters[index].dialog = !this.filters[index].dialog
        },
        resetMolecule(index) {
            this.filterStore.setSelectedDrugs([])
            this.refreshAllData()
            this.filters[index].dialog = !this.filters[index].dialog
        },
        async handleClick(filter, index) {
            if (filter.title === 'Reset') {
                this.filterStore.resetFilter()
                this.dataStore.resetState()
                this.$router.push({ name: 'home' }).catch(() => {
                    this.refreshAllData()
                })

                return
            }
            if (filter.route != null) {
                this.$router.push({ name: filter.route })

                return
            }
            this.filters[index].dialog = !this.filters[index].dialog
        },

        async refreshAllData(filter, index) {
            this.pharmacyListStore.loading = true

            // await this.dataStore.setDataObject(['MfrMarketSharePieChartWatchList'], {})
            // await this.dataStore.setPharmacyMoleculeList()
            // await this.pharmacyListStore.setMoleculeList()

            await Promise.all([
                this.dataStore.setPharmacyMoleculeList(),
                this.pharmacyListStore.setMoleculeList(),
                this.dataStore.setDataObject(['MfrMarketSharePieChartWatchList'], {}),
                this.filterStore.refreshCharts()
            ])

            // await this.filterStore.refreshCharts() // refresh all charts
            this.$root.$emit('refresh-all-charts')

            this.pharmacyListStore.loading = false
            if (index == null) {
                return
            }
            this.filters[index].dialog = false

            // this.btnKey += 1
        },
    },
}
</script>
