const sos = [
    {
        path: '/sos/newRatesTable',
        name: 'sos-rates-table',
        component: () => import('@/views/sos/newRatesTable.vue'),
        meta: {
            layout: 'content',
            title: 'SOS Rates Table',
            description: 'SOS Rates Table',
            breadcrumb: 'SOS Rates Table',
        },
    },
]

export default sos
