import { mdiTableKey } from '@mdi/js'

export default [
    {
        subheader: 'Smart Ordering',
    },
    {
        title: 'Rates',
        icon: mdiTableKey,
        to: 'sos-rates-table',
    },
]
