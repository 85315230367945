let api = process.env.VUE_APP_CHART_API

const debug = true

if (process.env.NODE_ENV === 'development' && debug === true) {
    api = 'http://localhost:3001'

    // api = 'https://gen-server.phox.app'
}

export default {
    api,
}
