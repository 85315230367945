function formatDate(date) {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()

    return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`
}

function getDateRange(hint) {
    const dateRange = []
    const today = new Date()
    const currentMonth = today.getMonth()
    let from
    let to
    switch (hint) {
    case 'Today':
        from = new Date(today.getFullYear(), today.getMonth(), today.getDate())
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate())
        break
    case 'Yesterday':
        from = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate())
        break
    case 'Last 7 Days':
        from = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6)
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate())
        break
    case 'Last 30 Days':
        from = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30)
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate())
        break
    case 'Last 90 Days':
        from = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 90)
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate())
        break
    case 'Last 180 Days':
        from = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 180)
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate())
        break
    case 'Last 365 Days':
        from = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 365)
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate())
        break

    case 'This Month':
        from = new Date(today.getFullYear(), today.getMonth(), 1)
        to = new Date(today.getFullYear(), today.getMonth() + 1, 1)
        break
    case 'Last Month':
        from = new Date(today.getFullYear(), today.getMonth() - 1, 1)
        to = new Date(today.getFullYear(), today.getMonth(), 1)
        break
    case 'This Quarter':
        let quarterStartMonth
        if (currentMonth < 3) {
            quarterStartMonth = 0
        } else if (currentMonth < 6) {
            quarterStartMonth = 3
        } else if (currentMonth < 9) {
            quarterStartMonth = 6
        } else {
            quarterStartMonth = 9
        }
        from = new Date(today.getFullYear(), quarterStartMonth, 1)
        to = new Date(today.getFullYear(), quarterStartMonth + 3, 1)
        break
    case 'Last Quarter':
        let previousQuarterStartMonth
        if (currentMonth < 3) {
            previousQuarterStartMonth = 0
        } else if (currentMonth < 6) {
            previousQuarterStartMonth = 3
        } else if (currentMonth < 9) {
            previousQuarterStartMonth = 6
        } else {
            previousQuarterStartMonth = 9
        }
        from = new Date(today.getFullYear(), previousQuarterStartMonth - 3, 1)
        to = new Date(today.getFullYear(), previousQuarterStartMonth, 1)
        break
    case 'This Year':
        from = new Date(today.getFullYear(), 0, 1)
        to = new Date(today.getFullYear() + 1, 0, 1)
        break
    case 'Last Year':
        from = new Date(today.getFullYear() - 1, 0, 1)
        to = new Date(today.getFullYear(), 0, 1)
        break
    default:
        from = new Date(today.getFullYear(), today.getMonth(), today.getDate())
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
    }
    dateRange[0] = from.toISOString().substr(0, 10)
    dateRange[1] = to.toISOString().substr(0, 10)

    return dateRange
}

export default getDateRange
