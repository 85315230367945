import { mdiHomeOutline } from '@mdi/js'
import market from './market'
import pharmacy from './pharmacy'
import tables from './tables'
import dashboard from './dashboard'
import sos from './sos'
import iqReport from './iqReport'

export default [
    {
        title: 'Home',
        icon: mdiHomeOutline,
        to: 'home',
    },

    ...pharmacy,
    ...sos,

    // ...dashboard,
    ...iqReport,
]
