/* eslint-disable import/order */
import config from '@/api/config'
import { getInstance } from '@/auth/authWrapper'
import { useFilterStore } from '@/stores/FilterStore'
import axios from 'axios'

const getChart = async (accessToken, query, chartType, dateRange, mfr, state, accNumbers, groupCode) => {
    try {
        const res = await axios.post(
      `${config.api}/generic/?agg=${query}`,
      {
          chartType,
          dateRange,
          mfr,
          state,
          accNumbers,
          groupCode,
      },
      {
          headers: {
              Authorization: `Bearer ${accessToken}`,
          },
      },
        )

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const GenDashPharmacyList = async body => {
    try {
        const res = await axios.post(`${config.api}/GenDashPharmacyList`, body, {
            headers: {
                Authorization: `Bearer ${await getInstance().getTokenSilently()}`,
            },
        })

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const GenDashTaroDrugs = async body => {
    try {
        const res = await axios.post(`${config.api}/GenDashTaroDrugs`, body, {
            headers: {
                Authorization: `Bearer ${await getInstance().getTokenSilently()}`,
            },
        })

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const GenDashMfrList = async body => {
    try {
        const res = await axios.post(`${config.api}/GenDashMfrList`, body, {
            headers: {
                Authorization: `Bearer ${await getInstance().getTokenSilently()}`,
            },
        })

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

// const GenDashData = async body => {
const GenDashData = async () => {
    try {
        const accessToken = await getInstance().getTokenSilently()
        const filterStore = useFilterStore()
        const res = await axios.post(`${config.api}/GenDashData`, filterStore.getFilterQuery, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

export default {
    getChart,
    GenDashPharmacyList,
    GenDashTaroDrugs,
    GenDashData,
    GenDashMfrList,
}
