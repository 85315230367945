import { authGuard } from '@/auth'

const pharmacy = [
    {
        path: '/pharmacy/pharmacy-molecule-list',
        name: 'pharmacy-molecule-list',
        beforeEnter: authGuard,
        component: () => import('@/views/pharmacy/pharmacyMoleculeList.vue'),
        meta: {
            layout: 'content',
            title: 'Pharmacy Molecule List',
            description: 'Top-Selling Drugs at Our Pharmacy: A Comprehensive List Sorted by Quantity and Sales',
            breadcrumb: 'Pharmacy Molecule List',
        },
    },
    {
        path: '/pharmacy/molecule-dashboard',
        name: 'molecule-dashboard',
        beforeEnter: authGuard,
        component: () => import('@/views/molecule/AnalyticsDashboard.vue'),
        meta: {
            layout: 'content',
            title: 'Molecule Dashboard',
            description: 'Molecule Dashboard',
            breadcrumb: 'Molecule Dashboard',
        },
    },
]

export default pharmacy
