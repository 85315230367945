import { useWindowSize } from '@vueuse/core'
import { watch } from 'vue'

export default () => {
    const setVh = () => {
        document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    }
    const { width: windowWidth } = useWindowSize()

    watch(windowWidth, setVh, { immediate: true })

    return {}
}
