import axios from 'axios'
import config from '@/api/config'
import { getInstance } from '@/auth/authWrapper'
import { useFilterStore } from '@/stores/FilterStore'
import { getDateRange } from '@/util/functions'

const getData = async (query, body) => {
    let filterStoreQuery = useFilterStore().getFilterQuery
    if (query === 'monthlyStackedSalesByMfrTar') {
        const tarRan = {
            mfr: ['TAR', 'RAN'],
            RxDate: getDateRange('Last 365 Days'),
        }
        const accNum = {
            Accreditation_Number: filterStoreQuery.Accreditation_Number,
        }
        filterStoreQuery = { ...tarRan, ...accNum }
        query = 'monthlyStackedSalesByMfr'
    }
    if (filterStoreQuery.RxDate == null) {
        const addRxDate = {
            RxDate: getDateRange('Last 30 Days'),
        }
        filterStoreQuery = { ...filterStoreQuery, ...addRxDate }
    }
    try {
        const res = await axios.post(
      `${config.api}/genDash/?agg=${query}`,
      {
          ...body,
          ...filterStoreQuery,
      },
      {
          headers: {
              Authorization: `Bearer ${await getInstance().getTokenSilently()}`,
          },
      },
        )

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const getDataNoStore = async (query, body) => {
    try {
        const res = await axios.post(
      `${config.api}/genDash/?agg=${query}`,
      {
          ...body,
      },
      {
          headers: {
              Authorization: `Bearer ${await getInstance().getTokenSilently()}`,
          },
      },
        )

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const getData2 = async (query, body) => {
    try {
        const res = await axios.get(`${config.api}/genDash/?agg=${query}`, {
            params: {
                ...body,
                ...useFilterStore().getFilterQuery,
            },
            headers: {
                Authorization: `Bearer ${await getInstance().getTokenSilently()}`,
            },
        })

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const getDataSync = async (query, body) => {
    try {
        const res = await axios.post(
      `${config.api}/genDash/?agg=${query}`,
      {
          ...body,
          ...(useFilterStore().getFilterQuery
          && Object.keys(useFilterStore().getFilterQuery).reduce((acc, key) => {
              if (key !== 'RxDate') {
                  acc[key] = useFilterStore().getFilterQuery[key]
              }

              return acc
          }, {})),
      },
      {
          headers: {
              Authorization: `Bearer ${await getInstance().getTokenSilently()}`,
          },
      },
        )

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const setPharmacyRatesData = async body => {
    try {
        const res = await axios.post(`${config.api}/setPharmacyRatesData`, body, {
            headers: {
                Authorization: `Bearer ${await getInstance().getTokenSilently()}`,
            },
        })

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const getPharmacyRatesData = async body => {
    try {
        const res = await axios.post(`${config.api}/getPharmacyRatesData`, body, {
            headers: {
                Authorization: `Bearer ${await getInstance().getTokenSilently()}`,
            },
        })

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const getSalesMarketShareAnalysis = async body => {
    try {
        const res = await axios.get(`${config.api}/SalesMarketShareAnalysis`, {
            // const res = await axios.get(`${config.api}/SalesMarketShareAnalysis`, body, {

            headers: {
                Authorization: `Bearer ${await getInstance().getTokenSilently()}`,
            },
        })

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

export default {
    getDataNoStore,
    getData,
    getData2,
    getDataSync,
    setPharmacyRatesData,
    getPharmacyRatesData,
    getSalesMarketShareAnalysis,
}
