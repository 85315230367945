<template>
  <div
    id="scroller"
  >
    <v-card flat>
      <v-card-text>
        <v-row class="match-height">
          <v-col
            cols="12"
            sm="4"
          >
            Commercial Rates for {{ $pinia.state.value.FilterStore.selectedPharmacy[0].pharmacyName }}
          </v-col>

          <v-col
            cols="12"
            offset-md="4"
            md="4"
          >
          </v-col>
        </v-row>
      </v-card-text>

      <div>
        <v-row class="match-height">
          <v-col
            cols="12"
            sm="12"
          >
            <v-card-text>
              <v-row>
                <v-col
                  id="tableOptionsButton"
                  cols="12"
                  md="3"
                >
                  <p class="tw-t">
                    Last Update: {{ lastUpdateFormatted }}
                    <v-btn
                      v-if="saving"
                      color="success"
                      loading
                      x-small
                    ></v-btn>
                  </p>
                </v-col>

                <v-col
                  id="searchField"
                  cols="12"
                  offset-md="5"
                  md="4"
                >
                  <v-text-field
                    v-model="search"
                    :append-icon="icons.mdiMagnify"
                    label="Search"
                    single-line
                    hide-details
                    dense
                    clearable
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-form
              ref="form"
              v-model="isValidRebateInput"
            >
              <v-data-table
                id="ratesDashboardDataTable"
                key="genericProductNumber"
                v-model="selected"
                single-select
                :headers="headers"
                :items="tableData"
                :search="search"
                :items-per-page="10"
                :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] }"
                :loading="loading"
                class="table-kitchen-sink"
                sort-by="salesSum"
                sort-desc
                @click:row="rowClick"
              >
                <template #item.salesSum="{ item }">
                  <div class="tw-pt-2 tw-mt-2">
                    {{ currencyConvert(item.salesSum == null ? 0 : item.salesSum ) }}
                  </div>
                </template>
                <template #item.DrugName="{ item }">
                  {{ item.genericName }} -- {{ item.str }} {{ item.form }}
                </template>
                <template #item.DailyQtyDispenseSum="{ item }">
                  {{ item.DailyQtyDispenseSum }}
                </template>
                <template #item.rebate="{ item, index }">
                  <div class="tw-pt-2 tw-mt-2">
                    <!-- <v-text-field
                      :key="index"
                      v-model="item.rebate"
                      :style="{backgroundColor: `hsl(${item.rebate * 1.2}, 100%, 75%)` }"
                      dense
                      hide-details
                      outlined
                      clearable
                      class=" custom-placeholder-chip input-center"
                      :rules="[integerValidator, betweeInclusiveValidator]"
                    ></v-text-field> -->
                    <div class="pr-4">
                      <v-slider
                        v-model="item.rebate"
                        :min="0"
                        :max="100"
                        :color="`hsl(${item.rebate * 1.1}, 80%, 50%)`"
                        class="align-center"
                        hide-details
                      >
                        <template #append>
                          <v-text-field
                            :key="index"
                            v-model="item.rebate"
                            hide-details
                            outlined
                            dense
                            max="100"
                            class="pt-0 mt-0 input-center"
                            type="number"
                            style="width: 70px"
                          ></v-text-field>
                        </template>
                      </v-slider>
                    </div>
                  </div>
                  <!-- :style="{ backgroundColor: `hsl(${getRebateValue(item, mfr) * 1.2}, 100%, 75%)` }" -->
                </template>
              </v-data-table>
            </v-form>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <!-- loading dialog -->
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="pt-3">
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="color"
      outlined
      :timeout="timeout"
    >
      {{ text }}

      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-footer>Last Update: {{ lastUpdateFormatted }}</v-footer>
  </div>
</template>

<script>
import { ref } from 'vue'
import { mdiMagnify } from '@mdi/js'
import { debounce } from 'lodash'
import { integerValidator, between } from '@core/utils/validation'
import { useDataStore } from '@/stores/Data'
import { useFilterStore } from '@/stores/FilterStore'
import { currencyConvert } from '@/util/functions'

export default {
    name: 'RebateTable',
    components: { },
    setup() {
        const selected = ref([])
        const loadData = ref([])
        const filterStore = useFilterStore()
        const dataStore = useDataStore()
        const tableData = ref([])

        return {
            selected,
            tableData,
            loadData,
            dataStore,
            filterStore,
            between,
            currencyConvert,
        }
    },
    data() {
        return {
            rowIndex: [],
            isFirstLoad: true,
            lastUpdate: new Date(),
            snackbar: false,
            text: 'Data Saved Successfully.',
            color: 'success',
            timeout: 2000,
            isValidRebateInput: true,
            saving: false,
            integerValidator,
            loading: false,
            search: '',
            icons: {
                mdiMagnify,
            },
            headers: [
                {
                    text: 'Drug',
                    value: 'DrugName',
                },

                // {
                //     text: 'DIN',
                //     value: 'DIN',
                // },
                {
                    text: 'Strength',
                    value: 'str',
                },
                {
                    text: 'Sales',
                    value: 'salesSum',
                },
                {
                    text: 'Qty',
                    value: 'DailyQtyDispenseSum',
                },
                {
                    text: 'Rebate',
                    value: 'rebate',
                },
            ],
        }
    },
    computed: {
        groupRebateValues() {
            const groupRebateValues = this.tableData.reduce((acc, item) => {
                const { genericProductNumber, rebate } = item
                acc[genericProductNumber] = rebate || 0

                return acc
            }, {})

            return groupRebateValues
        },
        lastUpdateFormatted() {
            const diff = (new Date() - this.lastUpdate) / 1000
            if (diff < 60) {
                return 'Now'
            } if (diff < 3600) {
                const mins = Math.floor(diff / 60)

                return `${mins} mins ago`
            }
            const hours = Math.floor(diff / 3600)

            return `${hours} hours ago`
        }
    },

    watch: {
        tableData: {
            handler: debounce(function () {
                if (!this.isValidRebateInput) {
                    this.text = 'Data Not Saved. Please Correct Invalid Entries'
                    this.color = 'error'
                    this.timeout = 50000
                    this.snackbar = true

                    // this.$refs.item.rebate.focus();

                    return
                }

                if (this.isFirstLoad) {
                    this.isFirstLoad = false

                    return
                }

                this.text = 'Data Saved Successfully.'
                this.timeout = 2000
                this.color = 'success'
                this.saveData()
            }, 1000),
            deep: true,
        },
    },

    async mounted() {
        this.lastUpdate = new Date()
        this.loading = true
        const [res1, res2] = await Promise.allSettled([
            this.dataStore.getPharmacyRatesData(),
            this.dataStore.setRatesDrugList()
        ])

        if (res1.status === 'rejected') {
            console.error(res1.reason)

            return
        }

        if (res2.status === 'rejected') {
            console.error(res2.reason)

            return
        }

        // this.tableData = this.mergeData(this.dataStore.pharmacyRatesData, this.dataStore.groupRebateValues)
        this.tableData = this.dataStore.tableData
        this.loading = false
    },
    methods: {
        rowClick(item, row) {
            this.rowIndex = this.rowIndex === row.index ? [] : [row.index]
            row.select(this.rowIndex.length > 0)
            this.selected = [item]
            this.filterStore.setSelectedDrugs(this.selected)
        },
        async saveData() {
            if (this.isValidRebateInput) {
                await this.dataStore.setPharmacyRatesData(this.groupRebateValues)
                this.lastUpdate = new Date()
                this.snackbar = true
            } else {
                this.text = 'Data Not Saved. Please Correct Invalid Entries'
                this.color = 'error'
                this.timeout = 5000
                this.snackbar = true
            }
        },
        mergeData(pharmacyRatesData, groupRebateValues) {
            if (!Object.keys(groupRebateValues).length) {
                console.error('groupRebateValues is an empty object')

                return []
            }

            const tableData = pharmacyRatesData.map(rate => ({
                ...rate,
                rebate: groupRebateValues[rate.genericProductNumber].toString()
            }))

            return tableData
        },

        betweeInclusiveValidator(value) {
            return between(value, 0, 100)()
        },
    },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';
.custom-placeholder-chip {
  padding: 0;
  max-height: 32px;
  border-radius: 5px;
}

.input-center input {
  text-align: center;
}

</style>
