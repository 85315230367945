/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia'
import { apiService, chartService } from '@/api'

// import { useFilterStore } from '@/stores/FilterStore'

// const FilterStore = useFilterStore()

export const usePharmacyListStore = defineStore('PharmacyListStore', {
    state: () => ({
        pharmacyList: [],
        moleculeList: [],
        loading: false,
    }),

    getters: {
        alphabetizedPharmacyList() {
            if (this.pharmacyList.length === 0) {
                return []
            }

            return this.pharmacyList

            // return sortPharmaciesByName(this.pharmacyList)

            // return this.pharmacyList.sort((a, b) => {
            //     const nameA = a.pharmacyName.toUpperCase()
            //     const nameB = b.pharmacyName.toUpperCase()
            //     if (nameA < nameB) {
            //         return -1
            //     }
            //     if (nameA > nameB) {
            //         return 1
            //     }

            //     return 0
            // })
        },
        pharmacyListBySales() {
            if (this.pharmacyList.length === 0) {
                return []
            }

            return this.pharmacyList.sort((a, b) => b.sales - a.sales)
        },
    },

    actions: {
        async setPharmacyList() {
            // console.log('settingPharmacyListAPI')
            // this.pharmacyList = []

            // if (this.pharmacyList.length === 0) {
            // console.log('settingPharmacyListAPI')
            // this.loading = true
            this.pharmacyList = await apiService.getData('pharmacyList', {})

            // this.loading = false

            // }

            return this.pharmacyList
        },
        async setMoleculeList() {
            // console.log('settingMoleculeListAPI')
            // this.moleculeList = []

            // if (this.moleculeList.length === 0) {
            // this.loading = true
            this.moleculeList = await chartService.GenDashTaroDrugs({})

            // this.loading = false

            // }

            return this.moleculeList
        },
        resetState() {
            // this.pharmacyList = []
            // this.moleculeList = []
        },
    },
})
