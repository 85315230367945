import { authGuard } from '@/auth'

const iqReport = [
    {
        path: '/iqreport/sales-market-share-analysis',
        name: 'sales-market-share-analysis',
        beforeEnter: authGuard,
        component: () => import('@/views/IQreport/SalesMarketShareAnalysis.vue'),
        meta: {
            layout: 'content',
            title: 'Sales & Market Share Analysis',
            description: 'Sales and Market Share Analysis: A Comparative Study of TAR and Competitor Sales',
            breadcrumb: 'Sales & Market Share Analysis',
        },
    },
]

export default iqReport
