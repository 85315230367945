import { Workbox } from 'workbox-window'

const wb = 'serviceWorker' in navigator ? new Workbox(`${process.env.BASE_URL}service-worker.js`) : null

if (wb) {
    const handleWaiting = () => {
        const answer = window.confirm('A new version of the content is available. Would you like to refresh the page now?')
        if (answer) {
            wb.removeEventListener('waiting', handleWaiting)
            wb.addEventListener('controlling', () => {
                window.location.reload()
            })
            wb.messageSkipWaiting()
        }
    }
    wb.addEventListener('waiting', handleWaiting)

    wb.register()
    wb.update()
}

export default wb
