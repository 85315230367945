/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia'
import { getInstance } from '@/auth/authWrapper'

function checkAuthorization(userStore) {
    if (!userStore['https://api.pharmaguide.ca/claims/app_metadata']) {
        return false
    }
    if (
        (userStore['https://api.pharmaguide.ca/claims/app_metadata'].role === 'superadmin'
      || userStore['https://api.pharmaguide.ca/claims/app_metadata'].role === 'taroUser')
    && Array.isArray(userStore['https://api.pharmaguide.ca/claims/app_metadata'].roles)
    && userStore['https://api.pharmaguide.ca/claims/app_metadata'].roles.includes('user')
    && Array.isArray(userStore['https://api.pharmaguide.ca/claims/app_metadata'].AccNum)
    && userStore['https://api.pharmaguide.ca/claims/app_metadata'].AccNum.length > 0
    ) {
        return true
    }

    return false
}

export const useUserStore = defineStore('UserStore', {
    state: () => ({
        user: {},
        token: null,
    }),

    getters: {
        getToken() {
            const data = getInstance().user
            this.setUser(data)

            return data
        },
        isUserAuthorized() {
            return checkAuthorization(this.user)
        },
    },

    actions: {
        setUser(data) {
            this.user = data
        },
        resetState() {
            //   this.role = ''
        },
    },
})
