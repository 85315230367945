/* eslint-disable eol-last */
/* eslint-disable import/prefer-default-export */

/**
 * return current date time
 * @returns return current date time
 *
 */

function currentDateTime() {
    const current = new Date()
    const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`
    const time = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`
    const dateTime = `${date} ${time}`

    return dateTime
}

export default currentDateTime