<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div
        class="navbar-content-container"
      >
        <div
          class="d-flex align-center left-row"
        >
          <v-icon
            v-if="!isVerticalNavMenuActive"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
          <!-- <v-icon
            v-if="route.name !== 'home' && !isVerticalNavMenuActive"
            large
            class="me-3"
            color="primary"
            @click="goHome"
          >
            {{ icons.mdiHomeCircle }}
          </v-icon> -->
          <v-breadcrumbs
            v-if="$vuetify.breakpoint.mdAndUp"
            :items="breadcrumbs.breadcrumbs"
          ></v-breadcrumbs>
        </div>

        <div
          class="d-flex align-center center-row"
        >
          <app-bar-filter></app-bar-filter>
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div
          v-if="$vuetify.breakpoint.mdAndUp"
          class="d-flex align-center right-row"
        >
          <active-filter></active-filter>
          <!-- <app-bar-user-menu></app-bar-user-menu> -->
        </div>
      </div>
    </template>
    <!-- <template #v-app-content>
      <v-container
        class="justify-center d-flex align-center text-h5"
        style="min-height: 50px;"
      >
        Content
      </v-container>
    </template> -->
    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <span>COPYRIGHT &copy; {{ new Date().getFullYear() }} <a
          href="https://pharmaguide.ca"
          class="text-decoration-none"
        >PharmaGuide Inc.</a><span class="d-none d-md-inline">, All rights Reserved</span></span>
        <div class="align-center d-none d-md-flex">
          <span>Hand-crafted &amp; Made with</span>
          <v-icon
            color="error"
            class="ms-2"
          >
            {{ icons.mdiHeartOutline }}
          </v-icon>
        </div>
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import { mdiMenu, mdiHeartOutline, mdiHomeCircle } from '@mdi/js'

// import { getVuetify } from '@core/utils'
// import { ref, watch } from 'vue'
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import navMenuItems from '@/navigation/vertical'
import { useUserStore } from '@/stores/User'
import { useBreadcrumbs } from '@/stores/Breadcrumbs'

// App Bar Components
import AppBarUserMenu from '@/components/AppBar/AppBarUserMenu.vue'
import AppBarFilter from '@/components/AppBar/AppBarFilter.vue'
import { sentryUser } from '@/api/sentry/sentry'
import { useRouter, useRoute } from 'vue-router/composables'
import activeFilter from '@/components/tables/activeFilter.vue'
import { getVuetify } from '@core/utils'

export default {
    components: {
        LayoutContentVerticalNav,
        AppBarUserMenu,
        AppBarFilter,
        activeFilter
    },
    setup() {
        const vuetify = getVuetify()
        const breadcrumbs = useBreadcrumbs()
        const router = useRouter()
        const route = useRoute()
        const userStore = useUserStore()
        const items = [
            {
                disabled: false,
                exact: true,
                text: 'Home',
                to: '/home'
            }
        ]

        const goHome = () => {
            if (route.name === 'home') return

            router.push({ name: 'home' })
        }

        return {
            vuetify,
            breadcrumbs,
            items,
            route,
            router,
            goHome,
            sentryUser,
            userStore,
            navMenuItems,
            icons: {
                mdiMenu,
                mdiHeartOutline,
                mdiHomeCircle
            },
        }
    },
    mounted() {
        if (process.env.NODE_ENV !== 'development') {
            sentryUser()
        }
    },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
</style>
