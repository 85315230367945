import { mdiTrendingUp } from '@mdi/js'

export default [
    {
        subheader: 'IQ Report',
    },
    {
        title: 'Sales & Market Share Analysis',
        icon: mdiTrendingUp,
        to: 'sales-market-share-analysis',
    },
]
