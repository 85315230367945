import {
    mdiChartLine, mdiPill, mdiChartDonut, mdiTableMultiple, mdiTableColumnWidth
} from '@mdi/js'

export default [
    {
        title: 'Market-Old',
        icon: mdiChartDonut,
        badgeColor: 'error',
        children: [
            // {
            //     title: 'Watch List',
            //     icon: mdiChartLine,
            //     to: 'market-watch-list',
            // },
            {
                title: 'Market Overview',
                icon: mdiChartLine,
                to: 'market-market-over-view',
            },
            {
                title: 'Molecules',
                icon: mdiPill,
                to: 'market-molecules',
            },
            {
                title: 'Drug Reference',
                icon: mdiPill,
                to: 'drug-reference',
            },
            {
                title: 'Rx Table',
                icon: mdiPill,
                to: 'rx-table',
            },
            {
                title: 'Charts (Single View)',
                children: [
                    {
                        title: 'chart1',
                        to: 'chart1',
                    },
                    {
                        title: 'chart2',
                        to: 'chart2',
                    },
                    {
                        title: 'chart3',
                        to: 'chart3',
                    },
                    {
                        title: 'chart4',
                        to: 'chart4',
                    },
                    {
                        title: 'chart5',
                        to: 'chart5',
                    },
                    {
                        title: 'chart6',
                        to: 'chart6',
                    },
                ],
            },
            {
                title: 'Tables',
                icon: mdiTableMultiple,

                // badge: '2',
                // badgeColor: 'error',
                children: [
                    {
                        title: 'Molecule List',
                        icon: mdiTableColumnWidth,
                        to: 'market-molecule-list',
                    },
                    {
                        title: 'Totals Per Molecule List',
                        icon: mdiTableMultiple,
                        to: 'market-total-molecule-list',
                    },
                ],
            },
        ],
    },

]
