<template>
  <div>
    <v-card flat>
      <v-card-title>
        <span class="selected-pharmacy">{{ (selectedPharmacy == null) ? 'Please select a pharmacy' : selectedPharmacy.pharmacyName }}</span>
      </v-card-title>
      <v-card-text>
        <!-- <v-autocomplete
          v-model="selectedPharmacy"
          :disabled="!pharmacyState"
          dense
          outlined
          :items="pharmacyListData"
          item-text="pharmacyName"
          return-object
          item-key="Accreditation_Number"
          height="40"
          :menu-props="{
            closeOnClick: false,
            closeOnContentClick: false,
            disableKeys: true,
            openOnClick: false,
            maxHeight: 304,
            offsetY: true,
            offsetOverflow: true,
            transition: false,
          }"
          label="Select Pharmacies"
          persistent-hint
          hide-details
          :multiple="!singleMode"
          @change="updateSelectedPharmacy"
        >
        </v-autocomplete> -->
        <v-text-field
          v-model="searchText"
          outlined
          dense
          label="Search pharmacies..."
        />
        <v-virtual-scroll
          :items="filteredPharmacies"
          :item-height="50"
          height="300"
        >
          <template #default="{ item }">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ item.pharmacyName }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  depressed
                  small
                  @click="selectPharmacy(item)"
                >
                  Select

                  <v-icon
                    v-if="item.Accreditation_Number !== filterStore.selectedPharmacy[0]?.Accreditation_Number"
                    color="orange darken-4"
                    right
                  >
                    {{ icons.mdiSelect }}
                  </v-icon>
                  <v-icon
                    v-if="item.Accreditation_Number === filterStore.selectedPharmacy[0]?.Accreditation_Number"
                    color="orange darken-4"
                    right
                  >
                    {{ icons.mdiSelectInverse }}
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { mdiAlertCircleOutline, mdiSelect, mdiSelectInverse } from '@mdi/js'
import { useFilterStore } from '@/stores/FilterStore'

import { usePharmacyListStore } from '@/stores/PharmacyListStore'

export default {
    name: 'PharmacySelect',
    props: {
        selectedPharmacyProp: {
            type: Object,
            default: () => {},
        },
    },
    setup() {
        const selectedPharmacy = ref([])
        const pharmacyListData = ref([])
        const sortPharmaciesByName = pharmacyList => pharmacyList.sort((a, b) => {
            const pharmacyA = a.pharmacyName.toLowerCase()
            const pharmacyB = b.pharmacyName.toLowerCase()

            if (pharmacyA < pharmacyB) {
                return -1
            }
            if (pharmacyA > pharmacyB) {
                return 1
            }

            return 0
        })
        const filterStore = useFilterStore()
        const searchText = ref('')
        const pharmacyListStore = usePharmacyListStore()
        const pharmacyState = ref(true)
        const singleMode = computed(() => !filterStore.multiple)

        const filteredPharmacies = computed(() => pharmacyListData.value.filter(pharmacy => {
            const pharmacyName = pharmacy.pharmacyName.toLowerCase()
            const search = searchText.value.toLowerCase()

            return pharmacyName.includes(search)
        }))

        // const fetchPharmacyList = async () => {
        //     await filterStore.fetchPharmacyList()
        // }

        // const resetPharmacy = () => {
        //     this.selectedPharmacy = []

        //     // filterStore.selectedPharmacy = []
        //     // filterStore.refreshAllData()
        // }

        return {
            searchText,
            filteredPharmacies,
            selectedPharmacy,
            pharmacyListData,
            filterStore,
            sortPharmaciesByName,
            pharmacyListStore,
            pharmacyState,
            singleMode,
            icons: {
                mdiAlertCircleOutline,
                mdiSelect,
                mdiSelectInverse
            }

            // fetchPharmacyList,
            // resetPharmacy,
        }
    },
    computed: {
        // selectedPharmacy() {
        //     if (this.filterStore.selectedPharmacy.length === 0) {
        //         return []
        //     }

        //     return this.filterStore.selectedPharmacy[0]
        // },

        // pharmacyListData() {
        //     if (this.pharmacyListStore.pharmacyList.length === 0) {
        //         return []
        //     }

    //     return this.pharmacyListStore.alphabetizedPharmacyList
    // },
    },
    watch: {
        // selectedPharmacy: {
        //     handler(val) {
        //         this.filterStore.setSelectedPharmacy([val])

        //         // this.filterStore.refreshAllData()
        //     },
        //     immediate: true,
        //     deep: true,
        // },
        selectedPharmacyProp: {
            handler(val) {
                this.selectedPharmacy = val
            },
            immediate: true,
            deep: true,
        },
    },
    mounted() {
        this.fetchPharmacyList()
    },

    // watch: {
    //     selectedPharmacy() {
    //         this.filterStore.selectedPharmacy = [this.selectedPharmacy]
    //         this.filterStore.refreshAllData()
    //     },
    // },
    methods: {
        updateSelectedPharmacy() {
            this.filterStore.setSelectedPharmacy([this.selectedPharmacy])
        },
        selectPharmacy(pharmacy) {
            this.selectedPharmacy = pharmacy
            this.filterStore.setSelectedPharmacy([pharmacy])
        },
        async fetchPharmacyList() {
            // this.selectedPharmacy = this.filterStore.selectedPharmacy
            this.pharmacyListData = this.sortPharmaciesByName(this.pharmacyListStore.pharmacyList)

            // console.log(
            //     '🚀 ~ file: pharmacySelect.vue:124 ~ fetchPharmacyList ~ this.pharmacyListStore.pharmacyList',
            //     this.pharmacyListStore.pharmacyList,
            // )

            // await this.filterStore.fetchPharmacyList()
        },
        resetPharmacy() {
            this.$emit('resetPharmacy')
            this.selectedPharmacy = []
            this.filterStore.setSelectedPharmacy([])
        },

    },
}
</script>

<style scoped>
.selected-pharmacy {
  font-weight: bold;
  font-size: 1.2em;
  color: #1e88e5; /* blue color, change as needed */
}
</style>
