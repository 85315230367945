import {
    mdiAirplaneTakeoff, mdiHeadLightbulbOutline, mdiNewspaperVariantMultipleOutline,
    mdiPencilBoxMultipleOutline
} from '@mdi/js'

const statusTypes = [
    {
        id: 0,
        value: 'newRx',
        title: 'New Prescriptions',
        icon: mdiNewspaperVariantMultipleOutline,
    },
    {
        id: 1,
        value: 'adaptingRx',
        title: 'Adapting Prescription',
        icon: mdiPencilBoxMultipleOutline,
    },
    {
        id: 2,
        value: 'pharmaOpinion',
        title: 'Pharmaceutical Opinion',
        icon: mdiHeadLightbulbOutline,
    },
    {
        id: 3,
        value: 'vacationSupply',
        title: 'Vacation Supply',
        icon: mdiAirplaneTakeoff,
    },

]

export default statusTypes
