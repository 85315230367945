/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia'

export const useBreadcrumbs = defineStore('Breadcrumbs', {
    state: () => ({
        breadcrumbs: [
            {
                disabled: false,
                exact: true,
                text: 'Home',
                to: '/home',
            },
        ],
    }),

    getters: {},

    actions: {
        addBreadcrumb(breadcrumb) {
            if (breadcrumb.text === 'Home') return
            this.breadcrumbs.push(breadcrumb)
        },
        removeBreadcrumb(text) {
            if (text === 'Home') return
            this.breadcrumbs = this.breadcrumbs.filter(b => b.text !== text)
        },
    },
})
