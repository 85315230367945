/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia'
import { usePharmacyListStore } from '@/stores/PharmacyListStore'

export const useFilterStore = defineStore('FilterStore', {
    state: () => ({
        dateRange: [],
        groupCode: [],
        selectedDrugs: [],
        mfr: [],
        threshold: 2000,
        marketShare: 20,
        rxListFilter: {},
        selectedPharmacy: [],
        singleMode: true,
    }),

    getters: {
        getFilterQuery: state => {
            let query = {}
            if (state.selectedPharmacy.length > 0) {
                query = { ...query, Accreditation_Number: state.selectedPharmacy.map(item => item.Accreditation_Number) }
            }
            if (state.dateRange.length > 0) {
                query = { ...query, RxDate: [`${state.dateRange[0]}`, `${state.dateRange[1]}T23:59:59Z`] }
            }
            if (state.selectedDrugs.length > 0) {
                query = {
                    ...query,
                    selectedDrugs: state.selectedDrugs,
                    genericProductNumber: state.selectedDrugs.map(item => item.genericProductNumber),
                }
            } else {
                query = { ...query, selectedDrugs: [], genericProductNumber: [] }
            }

            return query
        },
    },

    actions: {
        setDateRange(date) {
            this.dateRange = date
        },
        setGroupCode(groupCode) {
            this.groupCode = groupCode
        },
        setMfr(mfr) {
            this.mfr = mfr
        },
        setThreshold(threshold) {
            this.threshold = threshold
        },
        setMarketShare(marketShare) {
            this.marketShare = marketShare
        },
        setrxListFilter(data) {
            this.rxListFilter = data
        },
        setSelectedDrugs(selectedDrugs) {
            this.selectedDrugs = selectedDrugs
        },
        setSelectedPharmacy(selectedPharmacy) {
            this.selectedPharmacy = selectedPharmacy
        },
        setSingleMode(singleMode) {
            this.singleMode = singleMode
        },
        async refreshCharts() {
            const pharmacyListStore = usePharmacyListStore()
            await pharmacyListStore.setPharmacyList()
        },
        resetFilter() {
            this.dateRange = []
            this.groupCode = []
            this.selectedDrugs = []
            this.mfr = []
            this.threshold = 2000
            this.marketShare = 20
            this.rxListFilter = {}
            this.selectedPharmacy = []
            this.singleMode = true
        },
    },
})
