<template>
  <v-menu
    offset-y
    open-on-hover
  >
    <template #activator="{ on, attrs }">
      <v-btn
        class="filter-button"
        text
        large
        v-bind="attrs"
        block
        v-on="on"
      >
        {{ summary === '' ? 'Filter' : summary }}
      </v-btn>
    </template>
    <v-card
      flat
      class="filter-summary-card"
    >
      <v-card-text>
        <div class="filter-summary-title">
          Date Range:
        </div>
        <div
          v-if="dateRange && dateRange.length === 2"
          class="filter-summary-value"
        >
          From {{ dateRange[0] }} To {{ dateRange[1] }}
        </div>
        <div
          v-else
          class="filter-summary-value"
        >
          N/A
        </div>
        <br>
        <div class="filter-summary-title">
          Pharmacy:
        </div>
        <div
          v-if="selectedPharmacy && selectedPharmacy.length > 0"
          class="filter-summary-value"
        >
          {{ selectedPharmacy[0].pharmacyName }}
        </div>
        <div
          v-else
          class="filter-summary-value"
        >
          N/A
        </div>
        <br>
        <div class="filter-summary-title">
          Drug:
        </div>
        <div
          v-if="selectedDrugs && selectedDrugs.length > 0"
          class="filter-summary-value"
        >
          <ul class="selected-drugs">
            <li
              v-for="(drug, index) in selectedDrugs"
              :key="drug.id"
            >
              {{ drug.genericName }}
            </li>
          </ul>
        </div>
        <div
          v-else
          class="filter-summary-value"
        >
          N/A
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import { ref, watch, computed } from 'vue'
import { useFilterStore } from '@/stores/FilterStore'

export default {
    setup() {
        const filterStore = useFilterStore()

        const dateRange = ref(filterStore.dateRange)
        const selectedPharmacy = ref(filterStore.selectedPharmacy)
        const selectedDrugs = ref(filterStore.selectedDrugs)
        watch(
            () => filterStore.dateRange,
            newValue => {
                dateRange.value = newValue
            }
        )

        watch(
            () => filterStore.selectedPharmacy,
            newValue => {
                selectedPharmacy.value = newValue
            }
        )

        watch(
            () => filterStore.selectedDrugs,
            newValue => {
                selectedDrugs.value = newValue
            }
        )
        const summary = computed(() => {
            const summaryArray = []

            if (dateRange.value && dateRange.value.length === 2) {
                summaryArray.push(`${dateRange.value[0]} - ${dateRange.value[1]}`)
            }

            if (selectedPharmacy.value && selectedPharmacy.value.length > 0) {
                summaryArray.push(`${selectedPharmacy.value[0].pharmacyName}`)
            }

            if (selectedDrugs.value && selectedDrugs.value.length > 0) {
                const firstDrug = selectedDrugs.value[0]
                const otherDrugsCount = selectedDrugs.value.length - 1

                if (otherDrugsCount > 0) {
                    const limitedGenericName = firstDrug.genericName.slice(0, 10)
                    summaryArray.push(`${limitedGenericName} + ${otherDrugsCount} more`)
                } else {
                    const limitedGenericName = firstDrug.genericName.slice(0, 10)
                    summaryArray.push(limitedGenericName)
                }
            }

            return summaryArray.join(' | ')
        })

        return {
            summary,
            dateRange,
            selectedPharmacy,
            selectedDrugs,
        }
    },
}
</script>

<style scoped>
  .filter-button {
    background-color: #ffffff;
    color: #333333;
    border: 1px solid #333333;
  }

  .filter-button:hover {
    background-color: #333333;
    color: #ffffff;
    border: 1px solid #333333;
  }

 .filter-summary-card {
    background-color: #F8F8F8;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 0px;
  }

  .filter-summary-title {
    font-size: 16px;
    font-weight: 600;
    color: #555555;
  }

  .filter-summary-value {
    font-size: 14px;
    color: #333333;
  }

  .selected-drugs {
    margin: 0;
    padding-left: 20px;
  }

  .selected-drugs li {
    font-size: 14px;
    color: #333333;
    list-style-type: disc;
    margin-bottom: 5px;
  }
</style>
