<template>
  <!-- <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    max-width="300"
    offset-x
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        icon
        color="primary"
        v-on="on"
      >
        <v-icon>{{ icons.mdiCalendarMonth }}</v-icon>
        <v-icon>{{ icons.mdiChevronDown }}</v-icon>
      </v-btn>
    </template> -->
  <v-card flat>
    <v-card-title>Quick Select</v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-list
            nav
            dense
          >
            <v-list-item-group
              v-model="hints"
              color="primary"
            >
              <v-list-item
                v-for="(item, i) in options"
                :key="item.text"
                :value="item.text"
              >
                <v-list-item-content><v-list-item-title v-text="item.text"></v-list-item-title></v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- <v-card-actions>
      <v-btn
        block
        color="error"
        @click="refreshAllData"
      >
        Apply
      </v-btn>
    </v-card-actions> -->
  </v-card>
  <!-- </v-menu> -->
</template>
<script>
import { ref, watch } from 'vue'
import { mdiChevronDown, mdiCalendarMonth } from '@mdi/js'
import { getDateRange } from '@/util/functions'
import { useFilterStore } from '@/stores/FilterStore'

export default {
    setup(props, context) {
        const filterStore = useFilterStore()
        const menu = ref(false)
        const hints = ref('Last 30 Days')
        const options = [
            { text: 'Last 7 Days', value: 'last7days' },
            { text: 'Last 30 Days', value: 'last30days' },
            { text: 'Last 90 Days', value: 'last90days' },
            { text: 'Last 180 Days', value: 'last180days' },
            { text: 'Last 365 Days', value: 'last365days' },
        ]

        watch(hints, val => {
            const dateRange = getDateRange(val)
            filterStore.setDateRange(dateRange)
        }, { immediate: true })

        const icons = {
            mdiCalendarMonth,
            mdiChevronDown
        }

        const refreshAllData = () => {
            menu.value = false
            context.emit('refreshAllData')
        }

        return {
            options,
            filterStore,
            menu,
            hints,
            getDateRange,
            icons,
            refreshAllData
        }
    }
}
</script>
