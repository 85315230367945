import {
    mdiTableColumnWidth, mdiTableMultiple
} from '@mdi/js'

export default [
//     {
//   subheader: 'Data Tables',
// },
    {
        title: 'Tables',
        icon: mdiTableMultiple,

        // badge: '2',
        // badgeColor: 'error',
        children: [
            {
                title: 'Molecule List',
                icon: mdiTableColumnWidth,
                to: 'market-molecule-list',
            },
            {
                title: 'Totals Per Molecule List',
                icon: mdiTableMultiple,
                to: 'market-total-molecule-list',
            },
        ],
    },

]
